import React from "react"
import { services } from "../components/assets/data/dummydata"

export const Courses = () => {
  return (
    <>
      <section className='courses bg-[#F3F4F8] py-16 '>
        <div className='w-4/5 m-auto'>
          <div className='heading mb-16'>
            <h1 className='text-3xl font-semibold text-black'>
              Services <br />
              Nos solutions digitales sur mesure
            </h1>
            <span className='text-sm mt-2 block'>vous n'avez pas à lutter seul, vous pouvez compter sur notre assistance et notre aide.</span>
          </div>
          <div className='grid grid-cols-3 gap-8 md:grid-cols-1'>
            {services.map((item) => (
              <div className='box rounded-lg shadow-shadow1 bg-white'>
                <div className='images rounded-t-lg relative overflow-hidden h-40 w-ful'>
                  <img src={item.img} alt='' className='rounded-t-lg object-cover w-full h-full transition ease-in-out delay-150 cursor-pointer hover:scale-125 duration-300' />
                  <div className='categ flex gap-4 absolute top-0 m-3'>
                    <span className='text-[14px] bg-blue-400 p-1 px-3 text-white rounded-[5px] shadow-md'>Agency</span>
                    {/* <span className='text-[14px] bg-pink-700 p-1 px-3 text-white rounded-[5px] shadow-md'>Lifestyle</span> */}
                  </div>
                </div>
                <div className='text p-3'>
                  <h3 className='text-black my-4 font-medium h-10'>{item.title}</h3>
                  <p className="py-2 text-sm text-gray-400">{item.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}
