import img1 from '../images/122734.jpg'
import img2 from '../images/17564.jpg'
import img3 from '../images/2148695284.jpg'
import img4 from '../images/2150630943.jpg'
import img5 from '../images/36905.jpg'
import img6 from '../images/9319.jpg'

export const LinkData = [
  {
    id: 1,
    title: "Home",
    url: "/",
  },
  {
    id: 2,
    title: "About",
    url: "/about",
  },
  {
    id: 3,
    title: "services",
    url: "/courses",
  },
  {
    id: 4,
    title: "Réalisations",
    url: "/instructor",
  },
  {
    id: 5,
    title: "blog",
    url: "/blog",
  },
]
export const services  = [
  {
    id: 1,
    cover: "../images/course-2-1.jpg",
    title: "Email Marketing",
    desc: "Faites parler vos clients. Nous créons des campagnes d'emailing personnalisées et efficaces pour nourrir vos relations et booster vos ventes. Des newsletters engageantes aux campagnes promotionnelles ciblées, nous avons la solution pour vous.",
    img: img1
  },
  {
    id: 2,
    cover: "../images/course-4-1.jpg",
    title: "FDéveloppement Web et Mobile",
    desc: "Votre idée, notre expertise. Nous transformons vos projets en applications web et mobiles sur mesure. Des interfaces intuitives aux fonctionnalités avancées, nous créons des expériences digitales uniques pour vos utilisateurs.",
    img: img2
  },
  {
    id: 3,
    cover: "../images/course-5-1.jpg",
    title: "Voix off",
    desc: "Nos voix off professionnelles apportent du caractère à vos vidéos, vos présentations et vos contenus audio. Des voix chaleureuses et expressives pour une expérience auditive unique",
    img: img3
  },
  {
    id: 4,
    cover: "../images/course-6-1.jpg",
    title: "Photographie",
    desc: "Des images qui parlent: Nous créons des visuels percutants pour illustrer vos projets et séduire votre audience. Que ce soit pour votre site web, vos réseaux sociaux ou vos supports de communication, nous vous offrons des images sur mesure.",
    img: img4
  },
  {
    id: 5,
    cover: "../images/course11.jpg",
    title: "Gestion des Médias Sociaux",
    desc: "Soyez la star des réseaux sociaux ! Nous vous aidons à développer votre communauté, à créer du contenu engageant et à renforcer votre image de marque.  Facebook, Instagram, LinkedIn, TikTok... Nous maîtrisons tous les réseaux.",
    img: img5
  },
  {
    id: 6,
    cover: "../images/course12.jpg",
    title: "Veille Stratégique",
    desc: "Restez à l'affût des tendances. Notre service de veille stratégique vous permet d'anticiper les évolutions du marché et de prendre les bonnes décisions. Nous analysons vos concurrents, identifions les opportunités et vous tenons informés en temps réel.",
    img: img6
  },
]
