import React from "react";
import aboutImg from "../components/assets/images/young-woman-holding-tablet-work.jpg";
import banner from "../components/assets/images/36905.jpg"
// import aboutImgBanner from "../components/assets/images/about-banner.jpg"
// import imgs from "../components/assets/images/join1.png"
import { FaBookDead, FaChevronRight, FaRocket } from "react-icons/fa";
// import { AiOutlineCheck } from "react-icons/ai";

export const About = () => {
  return (
    <>
      <section className="about py-16">
        <div className="container">
          <div className="heading text-center py-12">
            <h1 className="text-3xl font-semibold text-black">
              Qui sommes-nous ?
            </h1>
            <span className="text-sm mt-2 block">
              l'Agence qui vous aidera à atteindre vos objectifs et à vous
              démarquer de la concurrence.
            </span>
          </div>
          <div className="grid grid-cols-1 gap-5 mt-4 lg:grid-cols-4">
            <AboutCard
              color="bg-[#2D69F0]"
              icon={<FaBookDead size={50} />}
              title="Réseaux sociaux"
              desc="Gestion et animation de vos profils "
            />
            <AboutCard
              color="bg-[#DD246E]"
              icon={<FaBookDead size={50} />}
              title="Création de contenu"
              desc="Blog, articles, infographies...nous nous occupons de tous "
            />
            <AboutCard
              color="bg-[#8007E6]"
              icon={<FaBookDead size={50} />}
              title="Development Web & Mobile"
              desc="Création et refonte de sites web "
            />
            <AboutCard
              color="bg-[#0CAE74]"
              icon={<FaBookDead size={50} />}
              title="SEO SEA"
              desc="Optimisation pour les moteurs de recherche "
            />
          </div>
        </div>
      </section>
      <AboutContent />
    </>
  );
};
export const AboutCard = (props) => {
  return (
    <div
      className={`box shadow-md p-5 py-8 rounded-md text-white ${props.color} cursor-pointer transition ease-in-out delay-150 hover:-translate-y-4 duration-300 `}
    >
      <div className="icon">{props.icon}</div>
      <div className="text mt-5">
        <h4 className="text-lg font-semibold my-3">{props.title}</h4>
        <p className="text-sm">{props.desc}</p>
      </div>
    </div>
  );
};

export const AboutContent = () => {
  return (
    <>
      <div className="px-2 py-16 mx-auto  md:max-w-full lg:max-w-screen-xl md:px-8 lg:px-8 lg:py-20">
        <div className="grid gap-10 lg:grid-cols-2 grid-cols-1 ">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            <div className="flex gap-5 items-center">
              <FaRocket className=" text-blue-400 rounded-full w-16 h-16" />
              <h2 className="max-w-xl mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl lg:text-4xl sm:leading-none">
                Let us handle {" "}
                <br className="hidden md:block" />
                your next{" "}
                <span className="inline-block text-blue-400">
                  destination
                </span>
              </h2>
            </div>
            <div className="max-w-xl mb-6">
              <p className="text-base text-gray-700 md:text-lg text-justify">
              Basée à Douala, notre agence est spécialisée dans la création de solutions digitales performantes et personnalisées. Grâce à notre expertise et notre passion pour l'innovation, nous aidons les entreprises à atteindre leurs objectifs et à se démarquer de la concurrence. Découvrez pourquoi nos clients nous font confiance.
              </p>
            </div>
            <div>
              <a
                href="/"
                aria-label=""
                className="border-1 border-blue-300 bg-blue-700 px-4 rounded inline-flex items-center font-semibold transition-colors duration-200 text-white py-2 hover:bg-yellow-500"
              >
                En savoir plus
               <FaChevronRight className="mx-4"/>
              </a>
            </div>
          </div>
          <div className="flex items-center justify-center -mx-4 lg:pl-8">
            <div className="flex flex-col items-end px-3">
              <img
                className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                src="https://images.pexels.com/photos/3184287/pexels-photo-3184287.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                alt=""
              />
              <img
                className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                src="https://images.pexels.com/photos/3182812/pexels-photo-3182812.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                alt=""
              />
            </div>
            <div className="px-3">
              <img
                className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80 align-top"
                src={aboutImg}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8 lg:px-8 lg:py-20">
        <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
          <div>
            <img
              className="object-cover w-full  rounded shadow-lg "
              src={banner}
              alt=""
            />
          </div>
          <div className="flex flex-col justify-center">
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                Votre projet {" "}
                <br className="hidden md:block" />
                nous tient{" "}
                <span className="inline-block text-blue-400">
                à cœur
                </span>
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
              Chez Agency, nous créons des solutions digitales sur mesure pour répondre à vos besoins spécifiques. Fort de notre expérience internationale, nous sommes en mesure de vous offrir une expertise pointue et un accompagnement personnalisé.
              </p>
            </div>
            <div className="grid gap-8 row-gap-8 lg:grid-cols-2">
              <div>
                <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
                  <svg
                    className="w-10 h-10 text-deep-purple-accent-400"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  La distance n'est pas une barriere.
                </h6>
                <p className="text-sm text-gray-900">
                Que vous soyez au Cameroun, en France ou ailleurs,  Agency est votre agence de confiance. 
                </p>
              </div>
              <div>
                <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
                  <svg
                    className="w-10 h-10 text-deep-purple-accent-400"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                approches interatives de la strategie d'entreprise
                </h6>
                <p className="text-sm text-gray-900">
                 Nous sommes à votre écoute afin d'élaborer la meilleure strategie pour vous propulser au sommet. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
